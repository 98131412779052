<template>
  <b-row class="page-settings-menu">
    <b-col>
      <PageHeader :title="$t('general.settingsMenu.title')" />

      <div v-for="group in groups" :key="group.id">
        <template v-if="group.show">
          <div class="page-settings-menu__group-title">
            <span
              class="page-settings-menu__font page-settings-menu__font--group-title"
            >
              {{ group.name }}
            </span>
          </div>

          <div class="page-settings-menu__settings">
            <SettingsMenuButton
              v-for="button in group.children"
              :key="button.id"
              :button="button"
            />
          </div>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import SettingsMenuButton from "@/features/ui/common/SettingsMenuButton.vue";

import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import {mapActions, mapGetters} from "vuex";
import User from "@/components/models/User";

export default {
  name: "PageSettingsMenu",
  mixins: [validationError, waitRequest],
  components: {
    SettingsMenuButton,
    PageHeader,
  },
  data() {
    return {
      buttonListGeneral: [
        {
          id: 1,
          name: this.$t("general.settingsMenu.button.myProfile"),
          addition: "",
          action: () => this.$router.push("/settings/my-profile"),
        },
        {
          id: 2,
          name: this.$t("general.settingsMenu.button.account"),
          addition: "",
          action: () => this.$router.push("/settings-account"),
        },
        {
          id: 3,
          name: this.$t("general.settingsMenu.button.privacy"),
          addition: "",
          action: () => this.$router.push("/settings-privacy"),
        },
        {
          id: 4,
          name: this.$t("general.settingsMenu.button.notifications"),
          addition: "",
          action: () => this.$router.push("/settings-notifications"),
        },
      ],
      buttonListOther: [
        {
          id: 1,
          name: this.$t("general.settingsMenu.button.becomePartner"),
          addition: "",
          action: () => this.$router.push("/become-partner"),
        },
        {
          id: 2,
          name: this.$t("general.settingsMenu.button.discord"),
          addition: "",
          disabled: true,
          action: () => {},
        },
      ],
      creatorTools: [
        {
          id: 1,
          name: this.$t("general.settingsMenu.button.subscription"),
          addition: "",
          action: () => this.$router.push("/settings-subscription"),
        },

        {
          id: 2,
          name: this.$t("general.settingsMenu.button.massMessage"),
          addition: "",
          action: () => this.$router.push("/mass-messages"),
        },
        // {
        //   id: 2,
        //   name: this.$t("general.settingsMenu.button.discord"),
        //   addition: "",
        //   disabled: true,
        //   action: () => {},
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({ isPartnerRole: "isPartnerRole", user: "user", }),
    isCreatorRole() {
      return this.user?.role?.includes(User.ROLE_CREATOR) && !this.isAnonymousUser;
    },
    groups() {
      return [
        {
          id: 1,
          name: this.$t("general.settingsMenu.groupName.general"),
          children: this.buttonListGeneral,
          show: true,
        },
        {
          id: 2,
          name: this.$t("general.settingsMenu.groupName.creatorTools"),
          children: this.creatorTools,
          show: this.isCreatorRole,
        },
        {
          id: 2,
          name: this.$t("general.settingsMenu.groupName.other"),
          children: this.buttonListOther,
          show: true,
        },
      ];
    },
  },

  mounted() {
    this.unitState();
  },

  methods: {
    ...mapActions({
      fetchPartnerStatus: "partner/fetchPartnerStatus",
    }),
    unitState() {
      this.getRole();
    },

    getRole() {
      if (this.requestInProgress || this.isPartnerRole) return;

      return this.waitRequest(() => {
        return this.fetchPartnerStatus().catch(err =>
        {
          console.log('err fetchPartnerStatus', err)
        })

      });
    },
  },
};
</script>

<style scoped lang="scss">
.page-settings-menu {
  margin-bottom: em(25);
  padding-left: 0;

  &__group-title {
    padding: em(21) 0;
  }

  &__title {
    margin-top: em(21);
  }

  &__settings {
    margin-left: em(-15);
    margin-right: em(-15);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__font {
    font-family: $font-default;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--group-title {
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }
  }

  &__icon {
    width: em(17);
    height: em(17);
    color: $app-gray-5;
  }
}
</style>
